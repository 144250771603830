<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col
        cols="12"
        lg="4"
        v-for="(curso, index) in filteredCursos"
        :key="index"
      >
        <v-hover>
          <template v-slot:default="{ hover }" v-if="curso.titulo">
            <base-material-card color="#aeaeae" :image="true">
              <v-img
                slot="image"
                eager
                :aspect-ratio="600 / 397"
                :src="getThumbnail(curso.thumbnail)"
              >
                <v-expand-transition>
                  <div
                    link
                    class="
                      d-flex
                      transition-fast-in-fast-out
                      primary
                      v-card--reveal
                      display-3
                      primary--text
                    "
                    style="
                      height: 100%;
                      background-color: rgba(0, 1, 57, 0.1) !important;
                    "
                  >
                    <detalhe :curso="curso" :key="'detalhe_' + index"></detalhe>
                  </div>
                </v-expand-transition>
              </v-img>

              <h4
                class="card-title font-weight-light mt-2 ml-2 d-inline-block"
                style="max-width: 95%; height: 55px"
              >
                {{ curso.titulo }}
              </h4>
              <div
                class="
                  col-12
                  d-block
                  mb-5
                  text-justify
                  font-weight-light
                  descricao-catalogo
                "
              >
                {{ curso.descricao }}
              </div>
              <!-- <p class="font-weight-light ml-2 mt-1 text-truncate d-inline-block" style="height: 85px;max-width: 95%; -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;">
                {{ curso.descricao }}
              </p> -->

              <template v-slot:actions>
                <v-btn elevation="2" color="#01E675" @click="whatsapp(curso)">
                  <v-icon dark left>mdi-whatsapp</v-icon>
                  {{ $t("contato") }}</v-btn
                >
                <!-- <span class="display-2 primary--text font-weight-bold"
                >Disponível</span
              >-->
                <v-spacer></v-spacer>
                <v-icon class="mr-1" small> mdi-clock-outline </v-icon>
                <span class="caption grey--text font-weight-normal"
                  >{{ curso.duracao || 0 }} horas/aula</span
                >
              </template>
              <template v-slot:actions2>
                <v-btn
                  elevation="2"
                  color="primary"
                  @click="checkout(curso)"
                  block
                  v-if="curso.linkCheckout"
                >
                  <v-icon dark left>mdi-cart-variant</v-icon>
                  {{ $t("comprar") }}</v-btn
                >
                <v-btn elevation="2" color="primary" disabled block v-else>
                  <v-icon dark left>mdi-cart-variant</v-icon>
                  {{ $t("Esgotado") }}</v-btn
                >
              </template>
            </base-material-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" lg="12" v-if="filteredCursos.length == 0">
        <p class="display-2 pa-5 text-center">
          Você ainda não está matriculado em um curso
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { db } from "@/db";
import { VMoney } from "v-money";
import Detalhe from "@/views/dashboard/component/Detalhe.vue";
// import Detalhe from './component/Detalhe.vue';
export default {
  components: { Detalhe },
  name: "DashboardDashboard",
  firebase() {
    // const ref = "/users/" + this.user.uid + "/cursos/";
    const ref2 = "/cursos/";
    return {
      // cursosUsuario: db.ref(ref2),
      cursos: db.ref(ref2),
    };
  },
  data() {
    return {
      cursosUsuario: [],
      cursos: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },

      headers: [],
      items: [],
      tabs: 0,

      list: {
        0: false,
        1: false,
        2: false,
      },
    };
  },

  methods: {
    ...mapMutations({
      setCourseTitle: "SET_COURSE_TITLE",
    }),
    complete(index) {
      this.list[index] = !this.list[index];
    },
    getThumbnail(link) {
      return link.replace(
        /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g,
        "https://drive.google.com/uc?export=download&id=$1"
      );
    },
    checkout(curso) {
      window.open(
        curso.linkCheckout,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    whatsapp(curso) {
      let url =
        "https://api.whatsapp.com/send?phone=" +
        curso.whatsapp +
        "&text=" +
        encodeURI("Olá, estou interessado no curso: " + curso.titulo);
      window.open(
        url,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    ver(curso) {
      let key = curso[".key"];
      let ultima = curso.ultima ? curso.ultima : "";
      this.setCourseTitle(curso.titulo);
      // this.$router.push("/curso/" + key + "/aula/" + curso.ultima);
      // this.$router.push("/curso/" + key + "/aula/" + ultima).catch((err) => {});
    },
    mapCurso(curso) {
      let key = curso[".key"];
      let aulas = curso && curso.aulas ? curso.aulas : [];
      let ultima = curso && curso.ultima ? curso.ultima : null;

      this.cursos.forEach((value) => {
        if (key === value[".key"]) {
          curso = value;
        }
      });

      return {
        ...curso,
        aulas: aulas,
        ultima: ultima,
        ".key": key,
      };
    },
    getValues(object) {
      let keys = Object.keys(object);
      let values = Object.values(object);

      keys.forEach(function (key, index) {
        values[index][".key"] = key;
      });
      return values;
    },
  },
  computed: {
    ...mapState(["user"]),
    filteredCursos() {
      let cursos = [];
      // let cursosValues = [];
      // cursosValues = Object.values(this.cursos);
      // console.log(this.cursos)
      // console.log(cursosValues)
      this.cursos.forEach((value) => {
        if (value.liberarCatalogo && value.whatsapp) {
          cursos.push(value);
        }
      });
      //   cursos = this.cursosUsuario.map(this.mapCurso);
      return cursos.reverse();
    },
  },
  mounted() {
    // console.log(this.cursosUsuario);
    // console.log(this.cursos);
  },
  directives: { money: VMoney },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.6;
  position: absolute;
  width: 100%;
}
.descricao-catalogo {
  height: 90px;
  overflow: hidden;
  max-width: 74ch!important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.descricao-catalogo:after {
  content: '...';
  position: absolute;
}
</style>
