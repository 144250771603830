<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen
    hide-overlay
    class="pa-0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on" color="primary">
        <v-icon color="white darken-4" left>
          mdi-arrow-right-drop-circle
        </v-icon>
        Saiba mais
      </v-btn>
    </template>

    <template>
      <v-card class="mx-auto my-12" max-width="374">
        <v-app-bar
          dark
          flat
          prominent
          shrink-on-scroll
          fade-img-on-scroll
          :src="getThumbnail(curso.thumbnail)"
          scroll-target="#scrolling-titulo"
          height="250"
        >
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <!-- <v-app-bar-title>Settings</v-app-bar-title> -->
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              gradient="to top right, rgba(100,115,201,0.5), rgba(25,32,72,0.5)"
            ></v-img>
          </template>
        </v-app-bar>
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <!-- <v-img
          height="250"
          :src="curso.thumbnail"
        ></v-img> -->

        <v-card-title
          id="scrolling-titulo"
          class="display-2 font-weight-black primary--text text-left"
          >{{ curso.titulo }}</v-card-title
        >

        <v-card-text>
          <!-- <v-row align="center" class="mx-0">
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>

            <div class="grey--text ms-4">4.5 (413)</div>
          </v-row> -->

          <div class="mb-4 text-subtitle-1">
            {{ curso.duracao || 0 }} horas/aula
          </div>

          <div>
            {{ curso.descricao }}
          </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>

        <v-card-title>Visão Geral</v-card-title>

        <v-card-text>
          <div v-html="curso.geral" v-if="curso" class="text-justify"></div>
        </v-card-text>

        <v-card-actions>
          <v-btn elevation="2" color="#01E675" @click="whatsapp(curso)">
            <v-icon dark left>mdi-whatsapp</v-icon>
            {{ $t("contato") }}</v-btn
          >
          <v-btn color="primary lighten-2" text @click.stop="dialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template></v-dialog
  >
</template>
  </v-dialog>
</template>
<script>
export default {
  name: "Detalhe",

  props: {
    curso: {},
  },

  data: () => ({
    dialog: false,
  }),
  methods: {
    whatsapp(curso) {
      let url =
        "https://api.whatsapp.com/send?phone=" +
        curso.whatsapp +
        "&text=" +
        encodeURI("Olá, estou interessado no curso: " + curso.titulo);
      window.open(
        url,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    getThumbnail(link){
      return link.replace(/https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=sharing/g, "https://drive.google.com/uc?export=download&id=$1");
    },
  },
};
</script>